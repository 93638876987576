import React, { useEffect, useRef } from "react"
import Konva from "konva"
import { Group, Rect, Text } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import { useTextSize } from "../../../enums/text_size"
import { CARD_MARGIN, CARD_SIZE } from "."
import { isEmpty } from "lodash"

export const CONVERSATION_STARTER_ID = "ConversationStarterRect"

const ConversationStarter = ({ content }) => {
  const enterAnimationR = useRef({})
  const nodeR = useRef()
  const fontSize = useTextSize("sm")
  const [{ x: startX, y: startY }] = usePositions([
    {
      x: [-1 * CARD_SIZE.x[0], -1 * CARD_SIZE.x[1]],
      y: [-1 * CARD_SIZE.y[0], -1 * CARD_SIZE.y[1]],
    },
  ])
  const [{ x, y }, { x: width, y: height }, { x: margin }] = usePositions([
    {
      x: [(1 - CARD_SIZE.x[0]) / 2, (1 - CARD_SIZE.x[1]) / 2],
      y: [0.16, 0.12],
    },

    CARD_SIZE,
    CARD_MARGIN,
  ])

  useEffect(() => {
    const duration = 0.6

    if (!isEmpty(enterAnimationR.current)) {
      enterAnimationR.current.moveX.reset()
      enterAnimationR.current.moveY.reset()
      enterAnimationR.current.rotate.reset()
    }

    enterAnimationR.current.moveX = new Konva.Tween({
      node: nodeR.current,
      duration,

      x: x + width / 2,

      easing: Konva.Easings.Linear,
    })
    enterAnimationR.current.moveY = new Konva.Tween({
      node: nodeR.current,
      duration,

      y: y + height / 2,

      easing: Konva.Easings.BounceEaseOut,
    })
    enterAnimationR.current.rotate = new Konva.Tween({
      node: nodeR.current,
      duration,

      rotation: 360,

      easing: Konva.Easings.EaseInOut,
    })

    enterAnimationR.current.moveX.play()
    enterAnimationR.current.moveY.play()
    enterAnimationR.current.rotate.play()
  }, [x, y, height, width, content])

  return (
    <Group
      x={startX + width / 2}
      y={startY + height / 2}
      offsetX={width / 2}
      offsetY={height / 2}
      id="ConversationStarter"
      ref={(n) => (nodeR.current = n)}
    >
      <Rect
        id={CONVERSATION_STARTER_ID}
        width={width}
        height={height}
        fill={COLORS.MAIN}
        cornerRadius={0.1 * height}
        stroke={COLORS.WHITE}
        strokeWidth={0.03 * height}
      />
      <Text
        x={margin}
        width={width - 2 * margin}
        y={margin}
        height={height - 2 * margin}
        align="center"
        verticalAlign="middle"
        fill={COLORS.WHITE}
        fontFamily="Lato"
        text={content}
        fontSize={fontSize}
        lineHeight={1.25}
      />
    </Group>
  )
}

export default ConversationStarter
