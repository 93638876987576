import React, { useCallback, useEffect, useRef } from "react"
import { Group } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import ButtonK from "../../../@konva/ui/ButtonK"
import { CARD_MARGIN, CARD_SIZE } from "."
import Konva from "konva"

const ConversationChoice = ({ position, content, chosen, onClick, onDragStart }) => {
  const startPositionR = useRef(position)
  const choiceR = useRef()
  const animationR = useRef()
  const [{ x, y }, { x: width, y: height }, { x: margin }] = usePositions([
    {
      x: [0.1 + startPositionR.current * 0.02, 0.01 + startPositionR.current * 0.04],
      y: [0.5 + startPositionR.current * 0.05, 0.37 + startPositionR.current * 0.03],
    },

    CARD_SIZE,
    CARD_MARGIN,
  ])

  const lift = useCallback((up = false) => {
    if (animationR.current) animationR.current.pause()

    animationR.current = new Konva.Tween({
      node: choiceR.current,
      duration: up ? 0.25 : 0.5,

      scaleX: up ? 1.15 : 1,
      scaleY: up ? 1.15 : 1,

      easing: up ? Konva.Easings.EaseIn : Konva.Easings.BounceEaseOut,
    })
    animationR.current.play()
  }, [])

  useEffect(() => {
    if (chosen) {
      if (animationR.current) animationR.current.pause()

      animationR.current = new Konva.Tween({
        node: choiceR.current,
        duration: 0.3,

        scaleX: 0.5,
        scaleY: 0.5,
        opacity: 0,

        easing: Konva.Easings.EaseIn,
      })
      animationR.current.play()
    }
  }, [chosen])

  return (
    <Group
      x={x + width / 2}
      y={y + height / 2}
      offsetX={width / 2}
      offsetY={height / 2}
      id="ConversationStarter"
    >
      <ButtonK
        innerRef={choiceR}
        width={width}
        height={height}
        margin={margin}
        rectProps={{ cornerRadius: 0.1 * height, stroke: COLORS.WHITE, strokeWidth: 0.03 * height }}
        text={content}
        size={"sm"}
        draggable={true}
        onClick={onClick}
        onDragStart={() => {
          lift(true)
          onDragStart()
        }}
        onDragEnd={() => {
          lift(false)
        }}
      />
    </Group>
  )
}

export default ConversationChoice
