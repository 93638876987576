let serverAddress
// serverAddress = "https://zlotetarasy.s.sprin.tech/";
// serverAddress = "https://phytopharm.s.sprin.tech/";
// serverAddress = "https://s4.sprintechlearning.com/";
// serverAddress = "https://kpmg.s.sprin.tech/";
// serverAddress = "https://s.ricoh.sprin.tech/";
// serverAddress = "https://dev.s.sprin.tech/";
// serverAddress = "https://swieta.s.sprin.tech/";
// serverAddress = "https://hr.s.sprin.tech/";
serverAddress = "https://s.sprin.tech/"
// serverAddress = "https://demo.s.sprin.tech/";
// serverAddress = 'http://192.168.0.4:8888/';
// serverAddress = 'http://localhost:8888/';

const CONFIG = {
  SERVER_ADDRESS: serverAddress,
  // INSTANCE_NAME: "hrsprintlearning",
  // INSTANCE_NAME: "canpack",
  INSTANCE_NAME: "default",

  ANIMATION_SPEED_MS: 1000,
  TIME_TO_READ_FEEDBACK_MS: 6000,
}

export default CONFIG
