import useGameSize from "../hooks/screen/useGameSize"
import { useEffect, useState } from "react"

const TEXT_SIZES = {
  sm: [30, 35],
  md: [40, 48],
  lg: [60, 72],
}

export const useTextSize = (sizeId) => {
  const { isVertical } = useGameSize()
  const [size, setSize] = useState(TEXT_SIZES[sizeId][isVertical ? 1 : 0])

  useEffect(() => {
    setSize(TEXT_SIZES[sizeId][isVertical ? 1 : 0])
  }, [sizeId, isVertical])
  return size
}

export default TEXT_SIZES
