import React, { useRef } from "react"
import { Group, Rect, Text } from "react-konva"
import COLORS from "../../enums/colors"
import Konva from "konva"
import { useTextSize } from "enums/text_size"

const ButtonK = ({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  margin = 0,

  draggable,

  size = "md",
  text,
  onClick,
  rectProps = {},
  innerRef,
  ...other
}) => {
  const fontSize = useTextSize(size)
  const tweenR = useRef()
  const rectR = useRef()

  const onHover = () => {
    if (tweenR.current) tweenR.current.pause()
    tweenR.current = new Konva.Tween({
      node: rectR.current,
      duration: 0.75,
      fill: COLORS.CLICKABLE_HOVER,
      easing: Konva.Easings.EaseInOut,
      onFinish: () => {
        tweenR.current.reverse()
      },
      onReset: () => {
        tweenR.current.play()
      },
    })
    rectR.current.getStage().container().style.cursor = "pointer"
    tweenR.current.play()
  }

  const onHoverLeave = () => {
    if (tweenR.current) tweenR.current.pause()
    tweenR.current = new Konva.Tween({
      node: rectR.current,
      duration: 0.25,
      fill: COLORS.CLICKABLE,
      easing: Konva.Easings.EaseInOut,
    })
    rectR.current.getStage().container().style.cursor = "default"
    tweenR.current.play()
  }

  return (
    <Group
      x={x + width / 2}
      y={y + height / 2}
      offsetX={width / 2}
      offsetY={height / 2}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
      onClick={onClick}
      onTap={onClick}
      draggable={draggable}
      ref={(n) => {
        if (innerRef) innerRef.current = n
      }}
      {...other}
    >
      <Rect
        width={width}
        height={height}
        fill={COLORS.CLICKABLE}
        ref={(n) => (rectR.current = n)}
        {...rectProps}
      />
      <Text
        x={margin}
        y={margin}
        width={width - 2 * margin}
        height={height - 2 * margin}
        align="center"
        verticalAlign="middle"
        fill={COLORS.WHITE}
        fontFamily="Lato"
        text={text}
        fontSize={fontSize}
        fontStyle="bold"
      />
    </Group>
  )
}

export default ButtonK
