import { useCallback, useEffect, useRef, useState } from "react"
import useGameSize, { MAX_NARROWER, MAX_WIDER } from "./useGameSize"

function usePositions(basePositions) {
  const { isVertical } = useGameSize()
  const basePositionsR = useRef(
    basePositions.map((p) => ({
      x: [(p.x?.[0] ?? 0) * MAX_WIDER, (p.x?.[1] ?? 0) * MAX_NARROWER],
      y: [(p.y?.[0] ?? 0) * MAX_NARROWER, (p.y?.[1] ?? 0) * MAX_WIDER],
    }))
  )

  const getCurrentPositions = useCallback(() => {
    const index = isVertical ? 1 : 0

    return basePositionsR.current.map((v) => ({
      x: v.x[index],
      y: v.y[index],
    }))
  }, [isVertical])

  const [positions, setPositions] = useState(getCurrentPositions())

  useEffect(() => {
    setPositions(getCurrentPositions())
  }, [getCurrentPositions])

  return positions
}

export default usePositions
