import React, { useCallback, useEffect } from "react"
import clsx from "clsx"

import Overlay from "components/Overlay/Overlay"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import Button from "components/Button"

import "./FeedbackCard.scss"
import { throttle } from "lodash"
import { useTranslation } from "react-i18next"

const DEFAULT_SUCCESS = "Świetnie!"
const DEFAULT_FAILURE = "Niestety nie."

const FeedbackCard = ({
  visible,
  content,
  successful = false,
  useDefaultFeedback = true,

  canClose = true,
  onFinished = () => {},
}) => {
  const { t } = useTranslation("common")

  if (!content && useDefaultFeedback) {
    content = successful ? DEFAULT_SUCCESS : DEFAULT_FAILURE
  }

  useEffect(() => {
    if (visible && !content) {
      setTimeout(onFinished, 1000)
    }
  }, [visible, onFinished, content])

  const innerOnFinished = useCallback(
    throttle(() => {
      onFinished()
    }, 1000),
    [onFinished]
  )

  return (
    <AnimatedElement
      visible={visible && !!content}
      className="FeedbackCard"
      durationMs={500}
      zIndex={100}
    >
      <Overlay zIndex={100} />
      <div
        className={clsx("feedback", {
          success: successful,
        })}
      >
        <p>{content}</p>
      </div>
      {canClose && (
        <div className="button-container">
          <Button className="md" onClick={innerOnFinished}>
            {t("continue")}
          </Button>
        </div>
      )}
    </AnimatedElement>
  )
}

export default FeedbackCard
