import React, { useEffect, useRef, useState } from "react"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import FullStage from "../../components/FullStage"
import { Group, Layer } from "react-konva"
import Element from "../../components/Element"
import ConversationStarter, { CONVERSATION_STARTER_ID } from "./subcomponents/ConversationStarter"
import ConversationChoices from "./subcomponents/ConversationChoices"
import { some } from "lodash"
import AnimationCorrectExplosion from "../../animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import { useMap } from "react-use"
import AnimationIncorrectExplosion from "../../animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion"

const ConversationsExercise = ({ state, inAnswersState, question, answers, answerChosen }) => {
  const [draggedAnswer, setDraggedAnswer] = useState(null)
  const stage_ref = useRef()
  const [feedback, { set, setAll }] = useMap({
    show: false,
    correct: true,
    position: { x: 0, y: 0 },
  })

  const onDragEnd = (e) => {
    const { current: stage } = stage_ref
    const position = stage.getPointerPosition()
    const intersections = stage.getAllIntersections(position)

    if (intersections && some(intersections, { attrs: { id: CONVERSATION_STARTER_ID } })) {
      answerChosen(draggedAnswer)
      setAll({
        show: true,
        correct: draggedAnswer.correct,
        position: {
          x: position.x,
          y: position.y,
        },
      })
    }

    setDraggedAnswer(null)
  }

  useEffect(() => {
    if (state === AS.DISAPPEARING) {
      set("show", false)
    }
  }, [set, state])

  return (
    <div className="ConversationsExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage stage_ref={stage_ref} onDragEnd={onDragEnd}>
          <Layer hitGraphEnabled={inAnswersState(AS.ACTIVE)}>
            <Group>
              <ConversationStarter content={question.content} />
              <ConversationChoices answers={answers} setDraggedAnswer={setDraggedAnswer} />
            </Group>
          </Layer>
        </FullStage>
      </Element>
      {feedback.show && feedback.correct && (
        <AnimationCorrectExplosion visible={true} x={feedback.position.x} y={feedback.position.y} />
      )}
      {feedback.show && !feedback.correct && (
        <AnimationIncorrectExplosion
          visible={true}
          x={feedback.position.x}
          y={feedback.position.y}
        />
      )}
    </div>
  )
}

export default ConversationsExercise
