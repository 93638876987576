import React, { useCallback, useRef } from "react"
import Konva from "konva"
import { Group, Rect } from "react-konva"

import useHVPosition from "../../../hooks/screen/useHVPosition"
import ButtonK from "../../../@konva/ui/ButtonK"
import COLORS from "../../../enums/colors"

const TrueFalseButtons = ({ answer, choose }) => {
  const { x: true_x, y, width, height } = useHVPosition({
    x: [0.1, 0.05],
    y: [0.84, 0.8],
    width: [0.3, 0.4],
    height: [0.15, 0.1],
  })

  const feedback_node_ref = useRef({
    is_true: null,
    is_false: null,
  })

  const { x: false_x } = useHVPosition({
    x: [0.6, 0.55],
  })

  const playFeedback = useCallback(
    (is_correct, is_true) => {
      const node = feedback_node_ref.current[is_true ? "is_true" : "is_false"]
      node.stroke(is_correct ? COLORS.SUCCESS : COLORS.ERROR)
      node.strokeWidth(0)
      node.opacity(1)

      new Konva.Tween({
        node,
        duration: 0.5,

        strokeWidth: height,
        opacity: 0,
      }).play()
    },
    [height]
  )

  const innerChoose = useCallback(
    (is_true) => {
      const is_correct = (answer.correct && is_true) || (!answer.correct && !is_true)
      choose(is_correct)
      playFeedback(is_correct, is_true)
    },
    [answer, choose, playFeedback]
  )

  return (
    <Group id="TrueFalseAnswer" y={y}>
      <Rect
        ref={(node) => (feedback_node_ref.current.is_true = node)}
        x={true_x}
        width={width}
        height={height}
      />
      <Rect
        ref={(node) => (feedback_node_ref.current.is_false = node)}
        x={false_x}
        width={width}
        height={height}
      />
      <ButtonK
        x={true_x}
        width={width}
        height={height}
        text="Prawda"
        onClick={() => innerChoose(true)}
      />
      <ButtonK
        x={false_x}
        width={width}
        height={height}
        text="Fałsz"
        onClick={() => innerChoose(false)}
      />
    </Group>
  )
}

export default TrueFalseButtons
