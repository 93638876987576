import React, { useEffect, useMemo, useState } from "react"
import { Group } from "react-konva"
import ConversationChoice from "./ConversationChoice"
import { keyBy } from "lodash"

const ConversationChoices = ({ answers, answerChosen, setDraggedAnswer }) => {
  const [topAnswerId, setTopAnswerId] = useState(0)
  const [sortedAnswerIds, setSortedAnswerIds] = useState(answers.map((answer) => answer.id))
  const answersById = useMemo(() => keyBy(answers, "id"), [answers])

  useEffect(() => {
    let newSortedAnswerIds = [...sortedAnswerIds]
    newSortedAnswerIds.sort((a, b) => (a === topAnswerId ? 1 : 0) - (b === topAnswerId ? 1 : 0))
    setSortedAnswerIds(newSortedAnswerIds)
    // eslint-disable-next-line
  }, [topAnswerId])

  return (
    <Group id="ConversationChoices">
      {sortedAnswerIds.map((id, index) => {
        const answer = answersById[id]
        return (
          <ConversationChoice
            key={answer.id}
            position={index}
            chosen={answer.answered}
            content={answer.content}
            onClick={() => setTopAnswerId(answer.id)}
            onDragStart={() => {
              setDraggedAnswer(answer)
              setTopAnswerId(answer.id)
            }}
          />
        )
      })}
    </Group>
  )
}

export default ConversationChoices
