import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import StateComponent from "../../base/StateComponent"

import "./AnimatedElement.scss"

const STATES = {
  HIDDEN: 0,
  WILL_APPEAR: 1,
  APPEARING: 2,
  VISIBLE: 3,
  HIDING: 4,
}

export default class AnimatedElement extends StateComponent {
  transitionTimeout

  static AnimationTypes = {
    fade: "fade",
    slideLeft: "slide-left",
    slideUp: "slide-up",
    popOut: "pop-out",
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    appearDelayMs: PropTypes.number,
    durationMs: PropTypes.number,
    animation: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    zIndex: PropTypes.number,

    fullSize: PropTypes.bool,
  }

  static defaultProps = {
    appearDelayMs: 0,
    durationMs: 500,
    animation: AnimatedElement.AnimationTypes.fade,
    fullSize: false,

    style: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      current: STATES.HIDDEN,

      visible: props.visible,
    }
  }

  componentDidMount() {
    let { visible } = this.props

    if (visible) {
      this.visibilityChanged()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transitionTimeout)
  }

  visibilityChanged = () => {
    clearTimeout(this.transitionTimeout)
    let { visible } = this.props

    if (visible) {
      this.delayedSetCurrentState(STATES.APPEARING, this.props.appearDelayMs, () => {
        this.transitionTimeout = setTimeout(this.show, this.props.durationMs)
      })
    } else {
      this.setCurrentState(STATES.HIDING, () => {
        this.transitionTimeout = setTimeout(this.hide, this.props.durationMs)
      })
    }
  }

  show = () => {
    this.setCurrentState(STATES.VISIBLE)
  }

  hide = () => {
    this.setCurrentState(STATES.HIDDEN)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.visibilityChanged()
    }
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={classNames(
          this.props.className,
          "AnimatedElement",
          `animation-${this.props.animation}`,
          {
            animate: this.inStates([STATES.APPEARING, STATES.HIDING]),
            visible: this.inState([STATES.APPEARING, STATES.VISIBLE]),
            hidden: this.inState(STATES.HIDDEN),
            "full-size": this.props.fullSize,
          }
        )}
        style={this.getStyling()}
      >
        {!this.inState(STATES.HIDDEN) && this.props.children}
      </div>
    )
  }

  getStyling = () => {
    return Object.assign({}, this.props.style, {
      transitionDuration: `${this.props.durationMs}ms`,
      animationDuration: `${this.props.durationMs}ms`,
      zIndex: this.props.zIndex,
    })
  }
}
